import React, { useEffect, useState, useRef } from "react";

import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import ReCAPTCHA from "react-google-recaptcha";
// import { ReCaptcha2  } from 'react-recaptcha-google';
import "./Modal.css";
import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'

import axios from 'axios';

import { Toast } from 'devextreme-react/toast';

const Houses = props => {  

    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Message, setMessage] = useState('');
    const [Status, setStatus] = useState('');
    const [visibleStatus, setVisileStatus] = useState(true);
    const [classStatus, setClassStatus] = useState('w-form-done');
    const [disabledi, setDisabled] = useState(true);
    const [ip, setIP] = useState('');
    const [adres, setAdres] = useState('');
    const [idAdres, setIdAdres] = useState(0);
    const [flat, setFlat] = useState('');
    const [houses, setHouse] = useState([]);
    const [emptyStatus, setEmptyStatus] = useState(true);

    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
      });    

    const captchaRef = useRef(null)

    async function getHouse() {
        await axios.get(`api/objects`)  
            .then(res => {
                setHouse(res.data);
            });
    }

    function updateEmptyStatus(valueEmptyStatus) {
        //        console.log('data', valueFileData)
                setEmptyStatus(valueEmptyStatus)
            }

    function updateIdAdres(valueIdAdres) {
        // console.log('valueIdAdres', valueIdAdres)
        const d = houses.find(item => item.adres === valueIdAdres)
        // console.log(d)
        // setAdres(valueIdAdres)
        setIdAdres(d.id?d.id:0)
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        setIP(res.data.IPv4)
      }

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
        document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
        setVisileStatus(false);
        setDisabled(true);
        getData();
        getHouse();
        setEmptyStatus(true);
    }, []);

    const onButtonPress = async (e) => {

        e.preventDefault();
        // console.log(filedata)
        //window.alert('sss '+Name)
        // window.alert(Email)
        // window.alert(Phone)
        // window.alert(Message)
        const dataEmail = {
            Name: Name, Email: Email, Phone: Phone, Message: Message, Ip: ip, Adres: adres, IdAdres: idAdres, Flat: flat
          }

        // console.log('dataEmail', dataEmail)

        setDisabled(true)
        await axios.post('api/mails', {
            Name: Name, Email: Email, Phone: Phone, Message: Message, Ip: ip, Adres: adres, IdAdres: idAdres, Flat: flat, TypeMail: 3
          })
          .then(function (response) {
            // console.log('OK', response);
            setClassStatus('w-form-done');
            setStatus('Ваше сообщение отправлено! Ваш IP ' + ip);
            setVisileStatus(true)
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'success' ,
                message: 'Ваше сообщение отправлено! Ваш IP ' + ip,
              });
          })
          .catch(function (error) {
            console.log(error);
            setClassStatus('w-form-fail');
            setStatus('Oops! Something went wrong while submitting the form.');
            setVisileStatus(true)
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error' ,
                message: 'Oops! Something went wrong while submitting the form.',
              });
          });

          setTimeout(() => {            
                document.getElementById('name-2').value = '';
                document.getElementById('email-2').value = '';
                document.getElementById('Phone-2').value = '';
                document.getElementById('field-2').value = '';
                document.getElementById('house-2').value = '';
                document.getElementById('flat-2').value = '';
                document.getElementById('upload-2').value = '';
                // document.getElementById('image-2').value = '';
                setVisileStatus(false)
                setDisabled(false)
                setEmptyStatus(true);
                // FileUpload.current.isEmptyFile()
            }, 2000);
            props.onClose();

          
    }

    function onChangeCaptcha(value) {
        // console.log('Captcha value:', value);
        setDisabled(false)
      }

    return ReactDOM.createPortal(
        <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
        >
        <div className="modal" onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <h4 className="modal-title">{props.title}</h4>
            </div>
            <div className="modal-body">
                {/* <div class="div-block-14"> */}
                <div>
                            <div class="w-form">
                                <form id="email-form" 
                                    name="email-form"
                                    data-name="Email Form" 
                                    // class="form"
                                    // onSubmit = {onButtonPress}
                                    >
                                        <input type="text" class="text-field w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="Имя" id="name-2"
                                        onChange = {(e) => setName(e.target.value)}>
                                        </input>
                                        <input type="email" class="text-field-2 w-input" maxlength="256" name="email-2" data-name="Email 2" placeholder="Email" id="email-2" required=""
                                        onChange = {(e) => setEmail(e.target.value)}></input>
                                        <input type="tel" class="text-field-2 w-input" maxlength="256" name="Phone-2" data-name="Phone 2" placeholder="Телефон" id="Phone-2" required=""
                                        onChange = {(e) => setPhone(e.target.value)}></input>
                                        <input type="text" id="house-2" name="house-2" class="text-field-2 w-input" placeholder="Введите адрес"
                                        onChange = {(e) => setAdres(e.target.value)}></input> 
                                        <textarea placeholder="Сообщение" maxlength="5000" id="field-2" name="field-2" data-name="Field 2" class="textarea w-input"
                                        onChange = {(e) => setMessage(e.target.value)}></textarea>
                                        {/* <input type="file" class="text-field-2 w-input" maxlength="256" name="File-2" data-name="File 2" placeholder="Файл" id="File-2" required=""
                                        onChange = {(e) => console.log(e.target.value)}>
                                        </input> */}
                                        {/* <ReCaptcha2
                                            ref={(el) => {this.captchaDemo = el;}}
                                            size="invisible"
                                            render="explicit"
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            onloadCallback={onLoadRecaptcha}
                                            verifyCallback={verifyCallback}
                                        /> */}
                                </form> 
                                {visibleStatus?
                                        <div>{Status}</div>
                                    :<></>
                                }
                            </div>
                        </div>
            </div>
            <div className="modal-footer">
                <input type="submit" value="Отправить" data-wait="Please wait..." class="submit-button w-button" disabled={disabledi} onClick = {onButtonPress} 
                ></input>
                <a> </a>
                <button onClick={props.onClose} class="submit-button w-button">
                Закрыть
                </button>
                <div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SECRET_KEY}
                        onChange={onChangeCaptcha}
                        size='normal'
                        ref={captchaRef}
                    />
                </div>
                
            </div>
            </div>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                // onHiding={onHiding}
                displayTime={3000}
            />
        </div>
        </CSSTransition>,
        document.getElementById("root")
    );
}  

export default Houses;