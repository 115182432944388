/* eslint-disable react-hooks/exhaustive-deps */
import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import newsDefaultJpg from "../../static/images/2456034.jpg";
import axios from 'axios';

import { useEffect, useState } from 'react';

const NewsList = () => {  
    //const [houses1, setHouse] = useState([]);
    const [news, setNews] = useState([]);
    
    async function getNews() {
        await axios.get(`${window.origin}/api/newss`)  
            .then(res => {
                setNews(res.data);
                console.log(res.data)
            });
      }

    useEffect(async () => {
        getNews()
    }, []);

 
    return (
        <div class="section-2 wf-section">
            <div class="container-9 w-container">
                <h1 class="heading-man">Новости</h1>
                <div class="div-block-21">
                    <div class="w-layout-grid grid-2">
                            {news.map((newsDetals, index) => (
                                <>
                                <div id="w-node-_4af29f3a-8e46-fbf0-25b7-1a0a3c52ed9d-c104c2b1">
                                    <div class="div-block-20">
                                        <img class="wrappernewslist newslist" src={"/static/news/"+newsDetals.id+"/"+newsDetals.pathPicture} loading="lazy" />
                                    
                                        <h4 class="heading-7">{newsDetals.date}</h4>
                                        <div class="text-block-10">
                                            <div dangerouslySetInnerHTML={{ __html: newsDetals.news_text }}/><br />
                                        </div>
                                        <a href={`/newsInDetal/${newsDetals.id}`}>Подробнее</a>
                                    </div>
                                </div>
                                </>
                        ))}
                    </div>
                </div>    
            </div>
        </div>
    )
}  

export default NewsList;
