import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import newsDefaultJpg from "../../static/images/2456034.jpg";

const newsInDetal = () => {  
   
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const idNews = useParams();
    //   window.alert(newstop4.find(e => e.id = (idNews?idNews.id:'1') ).news_text)
      // console.log(id.id, `${window.origin}/api/objects/${id?id.id:'1'}`,window)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [news, setNews] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [image, setImage] = useState(newsDefaultJpg)

    async function getNews() {
      setImage(newsDefaultJpg)
      await axios.get(`${window.origin}/api/newss/${idNews?idNews.id:'1'}`)  
          .then(res => {
              setNews(res.data);
              // console.log('n',res)
          });
      await axios.get(`${window.origin}/api/newss/show/${idNews?idNews.id:'1'}/`)  
          .then(res => {
            //  console.log(res)
                   const file = `${window.origin}/api/newss/show/${idNews?idNews.id:'1'}/`;
                   setImage((file))
               })
          .catch(function (error) {
            setImage(newsDefaultJpg)
            if (error.response) { // get response with a status code not in range 2xx
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.request) { // no response
              // console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else { // Something wrong in setting up the request
              // console.log('Error', error.message);
            }
            // console.log(error.config);
          });     
  }

    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect( () => {
      getNews();
    }, []);
    // console.log(news)

    const divStyle = {
      flex: '1 1 auto',
      margin: '0 5px'
    };

    return (
        <div class="section-2 wf-section">
            <div class="container-9 w-container">
            <h1 class="heading-object">{news.title}</h1>
                <div class="div-block-22">
                    <div class="div-block-23" style={divStyle}><img id='img_news' src={image} loading="lazy" alt=""/></div>
                    <div class="div-block-24" style={divStyle}>
                        <div class="text-block-11">
                            Дата: {news.date}<br />
                            
                            <div dangerouslySetInnerHTML={{ __html: news.news_text }}/>
                            <br />
                        </div>
                        <div>
                        {news.pathDoc!=''&&news.pathDoc!=null?<a href={`/api/newss/downloadDoc/${news.id}`} class="link5-6">
                          Приложение                            
                        </a>:<></>}
                        </div>
                    </div>
                </div> 

            </div>
        </div>
  )
}  

export default newsInDetal;