import React, { Component } from 'react';
import PersonalCabinet from '../Components/PersonalCabinet';

export default class personalcabinet extends Component {
    render() {
        return (
            <div>
                <PersonalCabinet />
            </div>
    )
    }
}
