import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import axios from 'axios';
import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageView from './ImageView/imageview.js'


const House = () => {  
    const [documnsHouse, setDocumnsHouse] = useState([]);
    //window.alert(id?id:'not')
    const id = useParams();
    // console.log(id.id, `${window.origin}/api/objects/${id?id.id:'1'}`)
    // console.log(id.id, `${window.origin}/api/documenthouses/?id_house=${id?id.id:'1'}`)
    const [house, setHouse] = useState('');
    const [pictureObject, setPictureObject] = useState([]);
    
    async function getHouse() {
        await axios.get(`${window.origin}/api/objects/${id?id.id:'1'}`)  
            .then(res => {
                setHouse(res.data);
                // console.log(res.data)
            });
    }
    async function getDocumHouse() {
        await axios.get(`${window.origin}/api/documenthouses/?id_house=${id?id.id:'0'}`)  
            .then(res => {
                // console.log('qq2', res.data)
                setDocumnsHouse(res.data);
            });
    }
    async function getPictureHouse() {
        await axios.get(`${window.origin}/api/pictureobjects/?id_object=${id?id.id:'1'}`)  
            .then(res => {
                console.log('qq2', res.data)
                setPictureObject(res.data);
            });
    }

    useEffect( () => {
        getHouse();
        getDocumHouse();
        getPictureHouse();
        }, []);

    function in_b(file_name_small) {
        let file_name_big = file_name_small
        const i = file_name_small.indexOf('.')
        // console.log(i, file_name_small.substring(0,i)+'_b'+file_name_small.substring(i,99))
        file_name_big = file_name_small.substring(0,i)+'_b'+file_name_small.substring(i,99)
        return file_name_big
    }    

    return (
        <div class="wf-section">
            <div class="container-9 w-container">
            <h1 class="heading-object">{house.adres}</h1>
                <div class="div-block-22">
                    <div class="div-block-24">
                        <div class="div-block-23"><img src={`/${house.logo_2}`} loading="lazy" alt=""/></div>
                        {pictureObject.map((pictures, index) => (
                                <>
                                    <p/>
                                    <ImageView src={`/${pictures.pic_1}`} border={false} zoom={true} src_big = {`/${in_b(pictures.pic_1)}`}></ImageView>
                                </>
                        ))}

                    </div>    
                    <div class="div-block-24">
                        <div class="text-block-11">
                            Год постройки: {house.build}<br />
                                            {house.wall ? `Материал стен: ${house.wall}`:''}{house.wall ? <br />:<></>}
                                            Количество этажей: {house.qty_etazh}<br />
                                            {house.qty_pod ? `Количество подъездов: ${house.qty_pod}`:''}{house.qty_pod ? <br />:<></>}
                                            {house.qty_flat ? `Количество помещений: ${house.qty_flat}`:''}{house.qty_flat ? <br />:<></>}
                                            {house.sq_all ? `Общая площадь дома (кв.м): ${house.sq_all}`:''}{house.sq_all ? <br />:<></>}
                                            {house.q_flats ? `Общая площадь жилых помещений (кв.м): ${house.q_flats}`:''}{house.q_flats ? <br />:<></>}                        </div>
                                            <br />Управляющий по дому: {house.manager}
                                            {documnsHouse.map((documens, index) => (
                                                <a href={`/api/documenthouses/download/${documens.id}`} class="link5-6">
                                                    {documens.name}
                                                </a>
                                            ))}

                    </div> 
                </div> 

            </div>
        </div>
  )
}  

export default House;