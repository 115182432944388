import React, { Component } from 'react';
import NewsList from '../Components/News/newsList';

export default class newsList extends Component {
    render() {
        return (
            <div> 
                <NewsList/>
            </div>
        )
    }
}
