import React, { Component } from 'react';
//import CarouselBox from '../Components/CarouselBox';
import NewsInDetal from '../Components/News/newsInDetal.js';

export default class newsInDetal extends Component {
    render() {
        return (
            <div> 
                <NewsInDetal/>
            </div>
        )
    }
}
