import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";
import ReCAPTCHA from "react-google-recaptcha";

// import pic_san from '../../static/images/pic_san.jpg';
// import afonyaGif from "../../static/images/afonya.gif";

import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'

import axios from 'axios';

const News = props => {  

    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [Message, setMessage] = useState('');
    const [serviceTxt, setServiceTxt] = useState('');
    const [visibleStatus, setVisileStatus] = useState(true);
    // const [classStatus, setClassStatus] = useState('w-form-done');
    const [disabledi, setDisabled] = useState(true);
    const [ip, setIP] = useState('');
    const [adres, setAdres] = useState('');
    const [idAdres, setIdAdres] = useState(0);
    // const [flat, setFlat] = useState('');
    const [services, setService] = useState([
        {id: 1, name: 'Прочие'},
        {id: 2, name: 'Сантехнические'},
        {id: 3, name: 'Электроснабжение'},
    ]);
    // const [emptyStatus, setEmptyStatus] = useState(true);
    const captchaRef = useRef(null)

    async function getService() {
        setService([
            {id: 1, name: 'Прочие'},
            {id: 2, name: 'Сантехнические'},
            {id: 3, name: 'Электроснабжение'},
        ])
    }

    function updateService(valueIdAdres) {
        console.log('valueIdAdres', valueIdAdres)
        const d = services.find(item => item.name === valueIdAdres)
        // console.log(d)
        // setAdres(valueIdAdres)
        setServiceTxt(valueIdAdres)
        setIdAdres(d.id?d.id:0)
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        // console.log(res.data);
        setIP(res.data.IPv4)
      }

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
        }
    };


    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
        document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
        setVisileStatus(false);
        setDisabled(true);
        getData();
        getService();
    });

    const onButtonPress = async (e) => {
        e.preventDefault();
        // console.log(filedata)
        //window.alert('sss '+Name)
        // window.alert(Email)
        // window.alert(Phone)
        // window.alert(Message)
        const dataEmail = {
            Name: Name, Email: Email, Phone: Phone, Message: Message, Ip: ip, Adres: adres, IdAdres: idAdres, Flat: ''
          }

        ('dataEmail', dataEmail)

        setDisabled(true)
        await axios.post('api/mails', {
            Name: Name, Email: Email, Phone: Phone, Message: Message, Ip: ip, Adres: adres, IdAdres: idAdres, Flat: '', TypeMail: 2, Service: serviceTxt
          })
          .then(function (response) {
            // console.log('OK', response);
            // setClassStatus('w-form-done');
            // setStatus('Thank you! Your submission has been received! Ваш IP ' + ip);
            setVisileStatus(true)
          })
          .catch(function (error) {
            // console.log(error);
            // setClassStatus('w-form-fail');
            // setStatus('Oops! Something went wrong while submitting the form.');
            setVisileStatus(true)
          });

          setTimeout(() => {            
                document.getElementById('name-2').value = '';
                document.getElementById('email-2').value = '';
                document.getElementById('Phone-2').value = '';
                document.getElementById('field-2').value = '';
                document.getElementById('house-2').value = '';
                document.getElementById('flat-2').value = '';
                document.getElementById('upload-2').value = '';
                // document.getElementById('image-2').value = '';
                setVisileStatus(false)
                setDisabled(false)
                // setEmptyStatus(true);
                // FileUpload.current.isEmptyFile()
            }, 2000);

            props.onClose();          
    }

    function onChangeCaptcha(value) {
        // console.log('Captcha value:', value);
        setDisabled(false)
      }

    return ReactDOM.createPortal(
        <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
        >
        <div className="modal" onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <h4 className="modal-title">{props.title}</h4>
            </div>
            <div className="modal-body">
                {/* {props.children}
                <img src={afonyaGif} alt="wait until the page loads" /> */}
                {/* <img src={pic_san} alt="wait until the page loads" /> */}
                <div>
                            <div class="w-form">
                                <form id="email-form" 
                                    name="email-form"
                                    data-name="Email Form" 
                                    class="form"
                                    // onSubmit = {onButtonPress}
                                    >
                                        <input type="text" class="text-field w-input" maxlength="256" name="name-2" data-name="Name 2" placeholder="Имя" id="name-2"
                                        onChange = {(e) => setName(e.target.value)}>
                                        </input>
                                        <input type="email" class="text-field-2 w-input" maxlength="256" name="email-2" data-name="Email 2" placeholder="Email" id="email-2" required=""
                                        onChange = {(e) => setEmail(e.target.value)}></input>
                                        <input type="tel" class="text-field-2 w-input" maxlength="256" name="Phone-2" data-name="Phone 2" placeholder="Телефон" id="Phone-2" required=""
                                        onChange = {(e) => setPhone(e.target.value)}></input>
                                        <input type="text" id="house-2" name="house-2" class="text-field-2 w-input" placeholder="Введите адрес"
                                        onChange = {(e) => setAdres(e.target.value)}></input> 
                                        <textarea placeholder="Сообщение" maxlength="5000" id="field-2" name="field-2" data-name="Field 2" class="textarea w-input"
                                        onChange = {(e) => setMessage(e.target.value)}></textarea>
                                        <input list="list_services" id="service-2" name="service-2" class="text-field-2 w-input" placeholder="Выберите услугу" 
                                        onChange = {(e) => {updateService(e.target.value)}}/>
                                            <datalist id="list_services">
                                                {services.map((service, index) => (
                                                        <option value={service.name}></option>
                                                    ))}
                                            </datalist><p/> 
                                </form>
                            </div>
                        </div>
                    </div>
                <div className="modal-footer">
                    <input type="submit" value="Отправить" data-wait="Please wait..." class="submit-button w-button" disabled={disabledi} onClick = {onButtonPress} 
                    ></input>
                    <a> </a>
                    <button onClick={props.onClose} class="submit-button w-button">
                    Закрыть
                    </button>
                    <div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SECRET_KEY}
                            onChange={onChangeCaptcha}
                            size='normal'
                            ref={captchaRef}
                        />
                    </div>

                </div>
            </div>
        </div>
        </CSSTransition>,
        document.getElementById("root")
    );
}  
export default News;