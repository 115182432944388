import React, { Component } from 'react';
//import CarouselBox from '../Components/CarouselBox';
import House from '../Components/Houses/house';

export default class house extends Component {
    render() {
        return (
            <div> 
                <House/>
            </div>
        )
    }
}
