
import React, { Component } from 'react';
//import CarouselBox from '../Components/CarouselBox';
import NewsDataGrid from '../Components/News/newsDataGrid.js';

export default class newsDataGrid extends Component {
    render() {
        return (
                <NewsDataGrid/>
        )
    }
}
