import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import "./NavigationDrawer.css";
import 'devextreme-react/text-area';  
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import React from 'react';

import Drawer from 'devextreme-react/drawer';
import RadioGroup from 'devextreme-react/radio-group';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import HTMLReactParser from 'html-react-parser';

import { useState, useEffect, useMemo, useCallback } from 'react';
import NavigationList from './NavigationList.js';
import NewsDataGrid from '../News/newsGrid.js';
import ObjectGrid from '../Objects/objectGrid.js';
import MailGrid from '../Mail/mailGrid.js';
import UsersGrid from '../Users/usersGrid.js';
import DocumentHouseGrid from '../DocumentHouse/documentHouseGrid';
import TopicGrid from '../Topics/topicGrid';
import DocumentTarifGrid from '../Tarif/documentTarifGrid';
import JobOpeningGrid from '../JobOpening/index.js';
import { Switch, Route } from "react-router-dom";

const Admin = props => {  
    const [open, setOpen] = useState(true);
    const [visibleNews, setvisibleNews] = useState(false);
    const [visibleObject, setObject] = useState(false);
    const [visibleMail, setMail] = useState(false);
    const [visibleUser, setUser] = useState(false);
    const [visibleDocHouse, setDocHouse] = useState(false);
    const [visibleTopic, setTopic] = useState(false);
    const [visibleTarifHouse, setTarifHouse] = useState(false);
    const [visibleJobOpening, setJobOpening] = useState(false);

    const buttonOptions = useMemo(() => {
        return {
            icon: "menu",
            onClick: () => {
                setOpen(!open );
            }
        };
    }, [open]);

    const renderList = useCallback(() => {
        const stateHandler = (newState) => setOpen(newState);
        const stateNews = (newNews) => setvisibleNews(newNews);
        const stateObject = (newObject) => setObject(newObject);
        const stateMail = (newMail) => setMail(newMail);
        const stateUser = (newUser) => setUser(newUser);
        const stateDocHouse = (newDocHouse) => setDocHouse(newDocHouse);
        const stateTopic = (newTopic) => setTopic(newTopic);
        const stateTarif = (newTarif) => setTarifHouse(newTarif);
        const stateJobOpening = (newJobOpening) => setJobOpening(newJobOpening);
        return (
            <div style={{width: 200 + "px"}}>
                <NavigationList 
                    stateHandler={stateHandler} 
                    stateNews={stateNews}
                    stateObject={stateObject}
                    stateMail={stateMail}
                    stateUser={stateUser}
                    stateDocHouse={stateDocHouse}
                    stateTopic={stateTopic}
                    stateTarif={stateTarif}
                    stateJobOpening={stateJobOpening}
            />
           </div>
        );
    }, []);

    function exitUser() {
        window.localStorage.setItem('in_fio', '')
        window.localStorage.setItem('in_role', '')
        window.localStorage.setItem('log', '1')
        window.location.reload();
    }

    return (
        <div class=" wf-section">
          <div class="container-9 w-container">
                    <Toolbar id="toolbar">
                        <Item
                            widget="dxButton"
                            options={buttonOptions}
                            location="before"
                        />
                        <Item>
                            {props.fio} ({props.role})  <a onClick={exitUser} style={{cursor:'pointer'}}>Выход</a>
                        </Item>    
                        <Item>
                            
                        </Item>

                    </Toolbar>
                    <Drawer
                        revealMode="expand"
                        openedStateMode="shrink"
                        minSize={37}
                        height={650}
                        render={renderList}
                        opened={open}
                    >
                        <div className={'content'} style={{ display: visibleNews ? "block" : "none" }}>
                            <NewsDataGrid />
                        </div> 
                        <div id="view" style={{ display: visibleObject ? "block" : "none" }}>
                            <ObjectGrid />
                        </div> 
                        <div id="view" style={{ display: visibleMail ? "block" : "none" }}>
                            <MailGrid />
                        </div> 
                        <div id="view" style={{ display: visibleUser ? "block" : "none" }}>
                            <UsersGrid />
                        </div> 
                        <div id="view" style={{ display: visibleDocHouse ? "block" : "none" }}>
                            <DocumentHouseGrid />
                        </div> 
                        <div id="view" style={{ display: visibleTopic ? "block" : "none" }}>
                            <TopicGrid />
                        </div> 
                        <div id="view" style={{ display: visibleTarifHouse ? "block" : "none" }}>
                            <DocumentTarifGrid />
                        </div> 
                        <div id="view" style={{ display: visibleJobOpening ? "block" : "none" }}>
                            <JobOpeningGrid />
                        </div> 
                        <div id="view">
                            <div class="wf-section">
                                <div class="container-9 w-container">
                                    <div class="div-block-22">
                                    </div>      
                                </div>    
                            </div>    
                        </div>
                    </Drawer>
                    </div>
        </div>                    
    )
}  

export default Admin;