import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import DataGrid, {
    Column,
    FormItem,
    Editing,
    Paging,
    Lookup,
    Popup,
    Form,
    Pager,
    FilterRow,
    HeaderFilter,
    AsyncRule,
    CustomRule,
  } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';  
import 'devextreme-react/text-area';  
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';

const jobOpening = () => {  
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const gridTarif = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const iddocumentTarifGrid = useParams();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [jobOpening, setJobOpening] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [idhouse, setidHouse] = useState(0)
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [idtarif, setidTarif] = useState(0)
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [fileName, setfileName] = useState('')
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [houses1, setHouse] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [rowIndexEdit, setrowIndexEdit] = useState(null);
    
    async function getHouse() {
        await axios.get(`${window.origin}/api/objects`)  
            .then(res => {
                setHouse(res.data);
            });
    }

    async function getJobOpening() {
      await axios.get(`${window.origin}/api/jobopenings`)  
          .then(res => {
              setJobOpening(res.data);
            //   console.log(res.data)
          });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect( () => {
        getJobOpening()
      }, []);

    function insertRow(e) {

      axios.post(`${window.origin}/api/jobopenings`, 
        { title : e.data.title === undefined?'':e.data.title, 
          text : e.data.text === undefined?'':e.data.text, 
          FileFull : e.data.FileFull === undefined?'':e.data.FileFull,
          RandomFileName : e.data.RandomFileName === undefined?'':e.data.RandomFileName
        })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
      })
    }
      
    function removeRow(e) {
      // console.log(e.key) // поле id
      const iddata = e.key
      axios.delete(`${window.origin}/api/jobopenings/${iddata}`)
      .then(res => {
        // console.log(res);
        // console.log(res.data);
      })
    }

    function updateRow(e) {
      const iddata = e.key
      axios.put(`${window.origin}/api/documenttarifs/${iddata}`, 
        { title : e.newData.title === undefined?e.oldData.title:e.newData.title, 
          text : e.newData.text===undefined?e.oldData.text:e.newData.text, 
          FileFull : e.newData.FileFull===undefined?e.oldData.FileFull:e.newData.FileFull, 
          RandomFileName : e.newData.RandomFileName === undefined?e.oldData.RandomFileName:e.newData.RandomFileName

      })
      .then(res => {
        // console.log(res);
        //console.log(res.data);
      })

    }

    // function onUploadStarted() {
    //   // this.setState({ chunks: [] });
    // }
  
    // function getValueInKb(value) {
    //   return `${(value / 1024).toFixed(0)}kb`;
    // }

    function onRowUpdated(e) {
      //console.log('edit', e, 'edit')
    }

    function onShowing(e) {
      // console.log('Showing', e, 'Showing')
    }

    function onEditingStart(e) {
    //   console.log('Editing', e.key, 'Editing')
    //   console.log('Editing1', gridTarif.current.instance.getRowIndexByKey(e.key), 'Editing1')
    //   setrowIndexEdit(gridTarif.current.instance.getRowIndexByKey(e.key))
    //   setidHouse( e.data.id_house)
    //   setidTarif( e.data.id)
    //   setfileName( e.data.full_path)
    }

    function onRowClick(e) {
        // console.log('onRowClick', e.rowIndex, 'onRowClick')
      }
  
    function idValue(e) {
      // console.log('c1', e.data.id_house, 'c1')
      setidHouse( e.data.id_house)
      setidTarif( e.data.id)
      return {result: 'Ok'}
    }

    function fileNameValue(e) {
      // console.log('c2', e.data.full_path, 'c2')
      setfileName( e.data.full_path)
      return {result: 'Ok'}
    }


    const notesEditorOptions = { height: 100 };

    const pageSizes = [10];

    function HrefCell(cellData) {
      return (
        <div>
          <a href={`/newsInDetal/${cellData.data.id}`}>Отправить резюме</a>
        </div>  
      );
    }
    return (
        <div class="wf-section">
            <div class="container-9 w-container">
            <h1 class="heading-object">Вакансии</h1>
                <div class="div-block-22">
                    <DataGrid
                        dataSource={jobOpening}
                        keyExpr="id"
                        // defaultColumns={columns}
                        showBorders={true}
                        onRowInserting={insertRow}
                        onRowRemoving={removeRow}
                        onRowUpdating={updateRow}
                        onEditingStart={onEditingStart}
                        onRowClick={onRowClick}
                        ref={gridTarif}
                    >
                    <Paging enabled={false} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={true}>
                    
                        <Popup title="Вакансии" showTitle={true} width={700} height={525} onShowing={onShowing}/>      
                        <Form>
                            <Item itemType="group" colCount={1} colSpan={2}>
                                <Item dataField="title" />
                                <Item dataField="text" 
                                    editorType="dxTextArea"
                                    colSpan={2}
                                    editorOptions={notesEditorOptions} />
                            </Item>    
                            
                        </Form>    
                    </Editing>      
                        <Column dataField="title" caption="Вакансия" width={250}>
                        </Column>          
                        <Column dataField="text" caption="Описание" width={250} />
                        <Column caption="отправить резюме" cellRender={HrefCell} width={70}/>
                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                        <Paging defaultPageSize={10} />
                    </DataGrid>    
                </div> 
            </div>
        </div>
  )
}  

export default jobOpening;