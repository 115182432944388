// import React from "react";
import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import './c.css'
import { forwardRef, useState,useEffect } from 'react';
import axios from 'axios';
// const fs = require('fs');

/**
 * Component to handle file upload. Works for image
 * uploads, but can be edited to work for any file.
 */
const FileUpload = forwardRef((fileFull) => {
  // State to store uploaded file
  const [file, setFile] = useState('');
  const [statusUpload, setStatusUpload] = useState("");

  // console.log('111',fileFull.emptyStatus)
  // if (fileFull.emptyStatus) {
  //   setFile('')
  // }

  function isEmptyFile() {
    setFile('')
  }

  useEffect(() => {
    // console.log('222',fileFull.emptyStatus)
    // setFile(React.createRef())
 }, []);

  // Handles file upload event and updates state
  function handleUpload(event) {
     setFile(event.target.files[0]);
    //  fileFull.fileFull = event.target.files[0];
    // fileFull.updateEmptyStatus(false);
    fileFull.updateFile(event.target.files[0].name);
    // let dataBuffer = fs.readFile('ssss.ss');
    // console.log('qq1',event.target.files[0])
    const filein  = event.target.files[0]
    filein.randomFileName = Math.ceil(Math.random() * 100000000);
    // console.log('qq2', filein)
    setFile(filein);
    setStatusUpload('');
    fileFull.setNameStatusUpload('Файл выбран: '+event.target.files[0].name+' Нажмите "Загрузить"')
    // console.log('qq', dataBuffer)
    //const q = updateFile;
    //fileFull = file.name
    // Add code here to upload file to server
    // ...
  }

  const uploadFile = (e) => {
    e.preventDefault();
    const formData = new FormData();        
    formData.append('file', file); // appending file
    axios.post('api/mails/upload', formData, file, {
        // onUploadProgress: (ProgressEvent) => {
        //     let progress = Math.round(
        //     ProgressEvent.loaded / ProgressEvent.total * 100) + '%';
        //     setProgess(progress);
        // }
    }).then(res => {
        console.log('sss',res);
        fileFull.updateFileData(res.data)
        setFile(res.data);
        // console.log(res.data.randomFileName, file.randomFileName)
        setStatusUpload('Файл загружен')
        // (fileFull.emptyStatus)
        fileFull.updateEmptyStatus(false)
        // getFile({ name: res.data.name,
        //          path: res.data.path
        //        })
    }).catch(err => {
        // console.log(err);
        setStatusUpload('Ошибка закгрузки файла '+err)
    })
  }

  return (
    <div id="upload-box">
      <label class="input-file-c">
        <input type="file" onChange={handleUpload} id="upload-2" />
        <span class="submit-button w-button">Выберите файл</span>           
	   	  <span class="input-file-c-text">{`${fileFull.statusNameUpload}`}</span>
      </label>
      {!fileFull.emptyStatus && <input type="text" class="text-field w-input" id='name-3' value={`Имя файла: ${file.name}`}></input>}
      {!fileFull.emptyStatus &&<input type="text" class="text-field w-input" id='type-3' value={`Тип файла: ${file.type}`}></input>}
      {!fileFull.emptyStatus &&<input type="text" class="text-field w-input" id='size-3' value={`Размер файла: ${file.size} bytes`}></input>}
      {/* {!fileFull.emptyStatus && file && <ImageThumb id="image-2" image={file} />} */}
      <p/>
      <button onClick={uploadFile} class="submit-button w-button">                   
        Загрузить
      </button>   
      {!fileFull.emptyStatus && <a class="input-file-c-text">{`      ${statusUpload}`}</a>}

    </div>
  );
})

/**
 * Component to display thumbnail of image.
 */
const ImageThumb = ({ image }) => {
  return <img src={URL.createObjectURL(image)} alt={image.name} />;
};


export default FileUpload;
