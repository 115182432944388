import React, { Component } from 'react';
import About from '../Components/About';
import Contact from '../Components/Contact';

export default class about extends Component {
    render() {
        return (
            <div>
                <About />
                <Contact />
            </div>
    )
    }
}
