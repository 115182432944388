import React, { Component } from 'react';

export default class Contacts extends Component {
    render() {
        return (
            <div>
                Это страница контактов
            </div>
        )
    }
}
