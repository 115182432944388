// import html from "./pc.html";

const PersonalCabinet = () => {  

    return (
        <div class="wf-section">
            <iframe title="Личный кабинет" src='./pc.html' width={950} height={600} frameborder="yes"></iframe>
        </div>
    )
}  

export default PersonalCabinet;
