import React, { Component } from 'react';
import Error from '../Components/Error';

export default class error extends Component {
    render() {
        return (
            <div>
                <Error />
            </div>
    )
    }
}
