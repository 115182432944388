import { useState } from 'react';
import logo from '../../static/images/lifee-group-logo.svg';
import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import Contact from "../Header/Contact.js"


const Footer = () => {  
    const [showContact, setShowContact] = useState(false);
    return (
    <>
        <div class="section-3 wf-section">
            <div class="container-8 w-container">
                <div class="div-block-14">
                    <div class="div-block-15"><img src={logo} loading="lazy" width="170" alt="" class="image-13"/>
                        <div class="text-block-6">2021 ООО УК &quot;ЛАЙФ ГРУПП&quot; <br /> ИНН: 6658476470 </div>
                    </div>
                    <div class="div-block-16">
                        <a href="/objects" class="link-4">Наши дома</a>
                        <a href="/about" class="link-4">О компании</a>
                        <a href="/" class="link-4">Услуги</a>
                        <a href="/newsList" class="link-4">Новости</a>
                        <a onClick={() => setShowContact(true)} class="link-4" style={{cursor:'pointer'}}>Контакты</a>
                    </div>
                    <div class="div-block-17">
                        <div class="text-block-7">+7 (343) 287-99-76</div>
                        <div class="text-block-8"><small>Аварийно диспетчерская служба</small> +7 (343) 226-97-01</div>
                        <div class="text-block-8">620146, Свердловская обл., г. Екатеринбург, ул. Шаумяна, дом № 87, 1 этаж офис УК</div>
                        <div class="text-block-9">Политика конфиденциальности</div>
                    </div>
                </div>
            </div>
        </div>
        <Contact title="Контакты" onClose={() => {setShowContact(false)}} show={showContact}>
        </Contact>     
    </>
  )
}  

export default Footer;