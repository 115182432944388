/* eslint-disable react/style-prop-object */
import React, { useEffect } from "react";
import DataGrid, { Column } from 'devextreme-react/data-grid';

import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";
// import '../css/webflow.css'
// import '../css/normalize.css'
// import '../css/life-group.webflow.css'
/*
{id: 1, dolzh: "Генеральный директор", fio: "Шевелев Антон Евгеньевич", phone: "", email: "a.shevelev@ukpioner.ru"}, 
{id: 2, dolzh: "Главный инженер", fio: "Быконя Антон Николаевич", phone: "", email: "A.Bykonya@uk-life.ru"}, 
{id: 3, dolzh: "Управляющий МКД: Многостаночников, 22а; С. Дерябиной, 53а; Славянская, 60", fio: "Быконя Антон Николаевич", phone: "", email: "A.Bykonya@uk-life.ru"}, 
{id: 4, dolzh: "Зам. генерального директора по юридическим вопросам", fio: "Винокурова Любовь Алексеевна", phone: "287-99-76 (вн.202)", email: "L.Vinokurova@uk-life.ru"}, 
{id: 5, dolzh: "Главный энергетик", fio: "Чернышев Леонид Валерьевич", phone: "", email: "lvch.1973@yandex.ru"}, 
{id: 6, dolzh: "Начальник расчетной группы", fio: "", phone: "287-99-76 (вн.202), пн,вт, чт, пт с 08:00 до 16:00 ; +7 922 14 77 561 (пн-чт с 17:30 до 20:30)", email: "9222116086n@gmail.com"}, 
{id: 7, dolzh: "Ведущий экономист расчетной группы", fio: "Ишниязова Елена Александровна", phone: "", email: "elyena.n@mail.ru"}, 
{id: 8, dolzh: "Отдел по работе с дебиторской задолженностью", fio: "Морозов Артем Александрович", phone: "+79220305595", email: ""},	
{id: 9, dolzh: "Репина, 68; Татищева, 126;Чкалова, 240;", fio: "Морозов Артем Александрович", phone: "", email: "artkinder9@gmail.com"}, 
{id: 10, dolzh: "Управляющий МКД: Гурзуфская, 11;", fio: "Панов Кирилл Валериевич", phone: "", email: "k.panov2016@yandex.ru"}, 
{id: 11, dolzh: "Управляющий МКД: Шаумяна, 87, 87/2;", fio: "Мусихин Александр Николаевич", phone: "", email: "sasha.musikhin.83@mail.ru"}, 
{id: 12, dolzh: "Управляющий МКД: Чайковского, 86в; Трактористов, 10;", fio: "Лаврентьев Ярослав Вячеславович", phone: "", email: "tchaikovskogo@mail.ru"}, 
{id: 13, dolzh: "Управляющий МКД: Инженерная, 61; Шолохова, 2/3, 2/2;", fio: "Штурбина Наталья Владимировна", phone: "", email: "natashturbina@yandex.ru"}, 
{id: 14, dolzh: "Управляющий МКД Латвийская, 54, Бахчиванджи, 22а;", fio: "Берко Вадим Николаевич", phone: "", phone: "", email: "latv54@yandex.ru"}, 
{id: 15, dolzh: "Приемная ООО УК «Лайф Групп»", fio: "", phone: "287-99-76", email: "info@uk-life.ru"}
*/
const contacts = [
    {id: 1, dolzh: "Генеральный директор", fio: "Шевелев Антон Евгеньевич", phone: "", email: ""}, 
    {id: 2, dolzh: "Начальник общего отдела", fio: "Кудряшова Наталья Михайловна", phone: "", email: "info@uk-life.ru"}, 
    {id: 3, dolzh: "Главный инженер, Многостаночников, 22а; С. Дерябиной, 53а; Славянская, 60; Шолохова, 2/2, 2/3", fio: "Быконя Антон Николаевич", phone: "", email: "A.Bykonya@uk-life.ru"}, 
    {id: 4, dolzh: "Зам. генерального директора по юридическим вопросам", fio: "Винокурова Любовь Алексеевна", phone: "", email: "L.Vinokurova@uk-life.ru"}, 
    {id: 5, dolzh: "Ведущий юрисконсульт", fio: "Другалева Юлия Расульевна", phone: "", email: "Y.Drugaleva@uk-life.ru"},
    {id: 7, dolzh: "Бухгалтерия", fio: "Шевелева Марина Викторовна", phone: "", email: "buh@uk-life.ru"},
    {id: 8, dolzh: "Главный энергетик", fio: "Чернышев Леонид Валерьевич", phone: "", email: ""}, 
    {id: 9, dolzh: "Начальник расчетной группы", fio: "Надежда Павловна", phone: "", email: ""}, 
    {id: 10, dolzh: "Ведущий экономист", fio: "Ишниязова Елена Александровна", phone: "", email: ""}, 
    {id: 11, dolzh: "Ведущий экономист", fio: "Медведевских Наталья Викторовна", phone: "", email: ""}, 
    {id: 12, dolzh: "Репина, 68; Татищева, 126;Чкалова, 240;Инженерная, 61; Шолохова, 2/2, 2/3;", fio: "Морозов Артем Александрович", phone: "", email: ""}, 
    {id: 13, dolzh: "Дебиторская задолженность", fio: "Волков Андрей Сергеевич", phone: "(343)361-88-68", email: ""},	
    {id: 14, dolzh: "Управляющий МКД: Гурзуфская, 11;", fio: "Кудряшова Наталья Михайловна", phone: "", email: ""}, 
    {id: 15, dolzh: "Управляющий МКД: Шаумяна, 87, 87/2;", fio: "Мусихин Александр Николаевич", phone: "", email: ""}, 
    {id: 16, dolzh: "Управляющий МКД: Чайковского, 86в; Трактористов, 10; Шолохова, 2/2, 2/3;", fio: "Лаврентьев Ярослав Вячеславович", phone: "", email: ""}, 
    {id: 17, dolzh: "Управляющий МКД Латвийская, 54, Бахчиванджи, 22а;", fio: "Берко Вадим Николаевич", phone: "", email: ""}, 
    // {id: 18, dolzh: "Сантехник: Татищева, 126; Репина, 68;", fio: "Виноградов Леонид", phone: "8902-502-34-27; 8902-878-08-17", email: ""}
    //{id: 15, dolzh: "Приемная ООО УК «Лайф Групп»", fio: "", phone: "287-99-76", email: "info@uk-life.ru"}
]

const Contact = props => {  

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
        props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
        document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    function cellRender(data) {
        return <div className="column-word"> {data.value} </div>;
    }

    return ReactDOM.createPortal(
        <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
        >
        <div className="modal" onClick={props.onClose}>
            <div className="modal-content-contact" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <h4 className="modal-title">{props.title}</h4>
            </div>
            <div className="modal-body">
                <DataGrid
                    dataSource={contacts}
                    keyExpr="id"
                    showBorders={true}
                    wordWrapEnabled={true}
                >
                    {/* <Column dataField="id" caption="Id" /> */}
                    <Column dataField="dolzh" caption="Должность" width={400}/>
                    <Column dataField="fio" caption="ФИО" width={250}/>
                    <Column dataField="phone" caption="Телефон" width={170}/>
                    <Column dataField="email" caption="Email" width={200}/>
                </DataGrid>    
            </div>
            <div className="modal-footer">
                <button onClick={props.onClose} class="submit-button w-button">
                    Закрыть
                </button>
            </div>
            </div>
        </div>
        </CSSTransition>,
        document.getElementById("root")
    );
}  

export default Contact;
