import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import DataGrid, {
    Column,
    FormItem,
    Editing,
    Paging,
    Lookup,
    Popup,
    Form,
    Pager,
    FilterRow,
    HeaderFilter
  } from 'devextreme-react/data-grid';
import { Item, Label } from 'devextreme-react/form';  
import 'devextreme-react/text-area';  
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';

const objectsGrid = () => {  

  const typeInput = [
    {id:'1', title:"Заявка с сайта"},
    {id:'2', title:"Заявка на услуги с сайта"},
    {id:'3', title:"Заявка на управление с сайта"},
    {id:'4', title:"Заявка ручная"},
    {id:'5', title:"Резюме соискателя"}
    ]

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const idMails = useParams();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mails, setMails] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [houses1, setHouse] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [user, setUser] = useState([]);
    // const [flat, setFlat] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks                
    const [services, setService] = useState([
      {id: 1, name: 'Прочие'},
      {id: 2, name: 'Сантехнические'},
      {id: 3, name: 'Электроснабжение'}
  ]);
  // eslint-disable-next-line react-hooks/rules-of-hooks                
  const [typeUser, setTypeUser] = useState([
      {id: 1, name: 'Физическое лицо'},
      {id: 2, name: 'Юридическое лицо'},
      {id: 3, name: 'Государственный орган'},
  ]);
  // eslint-disable-next-line react-hooks/rules-of-hooks                
  const [status, setStatus] = useState([
    {id: 1, name: 'Создан'},
    {id: 2, name: 'В работе'},
    {id: 3, name: 'Закрыт'},
]);
// const [emptyStatus, setEmptyStatus] = useState(true);

  async function getUser() {
    await axios.get(`${window.origin}/api/users`)  
        .then(res => {
            setUser(res.data);
        });
  }    

  async function getService() {
      setService([
          {id: 1, name: 'Прочие'},
          {id: 2, name: 'Сантехнические'},
          {id: 3, name: 'Электроснабжение'}
      ])
  }
  
    async function getHouse() {
        await axios.get(`${window.origin}/api/objects`)  
            .then(res => {
                setHouse(res.data);
            });
    }    
    

    async function getMails() {
      await axios.get(`${window.origin}/api/mails`)  
          .then(res => {
              setMails(res.data);
              // console.log(res.data)
          });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect( () => {
        getMails();
        getHouse();
        getService();
        getUser();
      }, []);

    function insertRow(e) {

      axios.post(`${window.origin}/api/mails`, 
        { Name : e.data.Name=undefined?'':e.data.Name, 
          Email : e.data.Email=undefined?'':e.data.Email, 
          Phone : e.data.Phone=undefined?'':e.data.Phone, 
          Message : e.data.Message=undefined?'':e.data.Message, 
          Ip : e.data.Ip=undefined?'':e.data.Ip, 
          IdAdres : e.data.IdAdres=undefined?'':e.data.IdAdres, 
          Flat : e.data.Flat=undefined?'':e.data.Flat, 
          FileFull : e.data.FileFull=undefined?'':e.data.FileFull,
          TypeMail: e.data.TypeMail=undefined?'':e.data.TypeMail,
          Service: e.data.Service=undefined?'':e.data.Service,
          TypeApplicant: e.data.TypeApplicant=undefined?0:e.data.TypeApplicant,
          TypeTreatment: e.data.TypeTreatment=undefined?'':e.data.TypeTreatment,
          Status: e.data.Status=undefined?'':e.data.Status,
          UserResponsible: e.data.UserResponsible=undefined?'':e.data.UserResponsible,
          UserExecutor: e.data.UserExecutor=undefined?'':e.data.UserExecutor,
          DateExecution: e.data.DateExecution=undefined?'':e.data.DateExecution,
          FileResponse: e.data.FileResponse=undefined?'':e.data.FileResponse,
          DateClose: e.data.DateClose=undefined?'':e.data.DateClose,
          IdClose: e.data.IdClose=undefined?'':e.data.IdClose,
          IdReturn: e.data.IdReturn=undefined?'':e.data.IdReturn,
          DateInput: e.data.DateInput=undefined?'':e.data.DateInput,
          NumberInput: e.data.NumberInput=undefined?'':e.data.NumberInput
            })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
      })
    }
      
    function removeRow(e) {
      // console.log(e.key) // поле id
      const iddata = e.key
      axios.delete(`${window.origin}/api/mails/${iddata}`)
      .then(res => {
        // console.log(res);
        // console.log(res.data);
      })
    }

    function updateRow(e) {
      const iddata = e.key
      // console.log(blobImage)
      axios.put(`${window.origin}/api/mails/${iddata}`, 
        { Name : e.newData.Name=undefined?e.oldData.Name:e.newData.Name, 
        Email : e.newData.Email=undefined?e.oldData.Email:e.newData.Email, 
        Phone : e.newData.Phone=undefined?e.oldData.Phone:e.newData.Phone, 
        Message : e.newData.Message=undefined?e.oldData.Message:e.newData.Message,  
        Ip : e.newData.Ip=undefined?e.oldData.Ip:e.newData.Ip,  
        IdAdres : e.newData.IdAdres=undefined?e.oldData.IdAdres:e.newData.IdAdres,  
        Flat : e.newData.Flat=undefined?e.oldData.Flat:e.newData.Flat,  
        FileFull : e.newData.FileFull=undefined?e.oldData.FileFull:e.newData.FileFull,  
        TypeMail: e.newData.TypeMail=undefined?e.oldData.TypeMail:e.newData.TypeMail,
        Service: e.newData.Service=undefined?e.oldData.Service:e.newData.Service,
        TypeApplicant: e.newData.TypeApplicant=undefined?e.oldData.TypeApplicant:e.newData.TypeApplicant,
        TypeTreatment: e.newData.TypeTreatment=undefined?e.oldData.TypeTreatment:e.newData.TypeTreatment,
        Status: e.newData.Status=undefined?e.oldData.Status:e.newData.Status,
        UserResponsible: e.newData.UserResponsible=undefined?e.oldData.UserResponsible:e.newData.UserResponsible,
        UserExecutor: e.newData.UserExecutor=undefined?e.oldData.UserExecutor:e.newData.UserExecutor,
        DateExecution: e.newData.DateExecution=undefined?e.oldData.DateExecution:e.newData.DateExecution,
        FileResponse: e.newData.FileResponse=undefined?e.oldData.FileResponse:e.newData.FileResponse,
        DateClose: e.newData.DateClose=undefined?e.oldData.DateClose:e.newData.DateClose,
        IdClose: e.newData.IdClose=undefined?e.oldData.IdClose:e.newData.IdClose,
        IdReturn: e.newData.IdReturn=undefined?e.oldData.IdReturn:e.newData.IdReturn,
        DateInput: e.newData.DateInput=undefined?e.oldData.DateInput:e.newData.DateInput,
        NumberInput: e.newData.NumberInput=undefined?e.oldData.NumberInput:e.newData.NumberInput
  
      })
      .then(res => {
        // console.log(res);
        //console.log(res.data);
      })

    }

    function onUpload(e) {

      const file = e.file;
      // const fileReader = new FileReader();
      // fileReader.onload = () => {
      //   setImage(fileReader.resultl)
      //   console.log(file)
      // };
      // fileReader.readAsDataURL(file);

      
      const file2 = JSON.parse(e.request.responseText)
      // console.log('bb', file2)
      // console.log('aa', file.file.data.data)
      // var blob = file.file.data.data;
      // console.log(typeof blob)
      // const fs = require("fs");
      // // fs.writeFile("hello.txt", blob)

      // // // Convert the string to bytes
      // // var bytes = new Uint8Array(data.length / 2);

      // // for (var i = 0; i < data.length; i += 2) {
      // //     bytes[i / 2] = parseInt(data.substr(i, i + 2), /* base = */ 16);
      // // }

      // // // Make a Blob from the bytes
      // // var blob = new Blob([bytes], {type: 'image/bmp'});

      // var urlCreator = window.URL || window.webkitURL;
      // const objUrl = urlCreator.createObjectURL(new Blob(blob, {type: "image/png"}));
      // //window.open(objUrl)

      // // console.log(objUrl) 

      // let img = document.querySelector('img_news');
      // const v = new Blob(blob, {type: "image/png"})
      let reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onloadend = () =>  ('ЭТО BASE64 -> ',reader.result)
      
      const urlsrc =  URL.createObjectURL(file)
    //   setImage(urlsrc)
      // setpathPicture(urlsrc)
    //   setblobImage(file)
      // console.log('url', urlsrc)




      // var URL = 'data:image/jpg;base64,'+data.data;
      // document.querySelector('#img').src = URL;

      // setImage(objUrl)
      // const chunk = {
      //   segmentSize: e.segmentSize,
      //   bytesLoaded: e.bytesLoaded,
      //   bytesTotal: e.bytesTotal,
      // };
      // this.setState({ chunks: [...this.state.chunks, chunk] });
    }
  
    // function onUploadStarted() {
    //   // this.setState({ chunks: [] });
    // }
  
    // function getValueInKb(value) {
    //   return `${(value / 1024).toFixed(0)}kb`;
    // }

    function onFilesUploaded(e) {
    }

    function onUploadStarted(e) {

      // console.log("start", e.file.name, e,'qqq')
      const fileName = e.file.name
    //   setpathPicture(fileName)
      // axios.post(`${window.origin}/api/newss/saveupload`, 
      //    { fileName : fileName, 
      //    })
      //  .then(res => {
      //    console.log(res);
      // })      
    }
    function onRowUpdated(e) {
      // console.log('edit', e, 'edit')
    }
    function onShowing(e) {
      // console.log('Showing', e, 'Showing')
    }
    async function onEditingStart(e) {
      // console.log('Editing', e.data.id, 'Editing')
    }


    const notesEditorOptions = { height: 100 };

    const pageSizes = [10];
    return (
      <div class="container-9 w-container">
          <h1 class="heading-object">Входящие обращения</h1>
              <div class="div-block-22">      
                    <DataGrid
                        dataSource={mails}
                        keyExpr="id"
                        // defaultColumns={columns}
                        showBorders={true}
                        onRowInserting={insertRow}
                        onRowRemoving={removeRow}
                        onRowUpdating={updateRow}
                        onEditingStart={onEditingStart}
                    >
                    <Paging enabled={false} />
                    <FilterRow visible={true} />
                    <HeaderFilter visible={true} />
                    <Editing
                        mode="popup"
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={true}>
                    
                        <Popup title="Входящие обращения" showTitle={true} width={700} height={525} onShowing={onShowing}/>      
                        <Form>
                            <Item itemType="group" colCount={1} colSpan={2}>
                                <Item dataField="NumberInput" />
                                <Item dataField="DateInput" />
                                <Item dataField="Name" />
                                <Item dataField="Email" />
                                <Item dataField="Phone" />
                                <Item
                                  dataField="Message"
                                  editorType="dxTextArea"
                                  colSpan={2}
                                  editorOptions={notesEditorOptions} />
                                <Item dataField="Ip" />
                                <Item dataField="Adres" />
                                <Item dataField="IdAdres" />
                                <Item dataField="Flat" />
                                <Item dataField="FileFull" />
                                <Item dataField="Service" />
                                <Item dataField="TypeApplicant" />
                                <Item dataField="TypeTreatment" />
                                <Item dataField="Status" />
                                <Item dataField="UserResponsible" />
                                <Item dataField="UserExecutor" />
                                <Item dataField="DateExecution"/>
                                <Item dataField="FileResponse"/>
                                <Item dataField="DateClose"/>
                                <Item dataField="IdClose" />
                                <Item dataField="IdReturn" />
                              </Item>  
                        </Form>    
                    </Editing>    
                        <Column dataField="NumberInput" caption="Номер" width={100} />
                        <Column dataField="DateInput" dataType="date" caption="Дата" width={100}/> 
                        <Column dataField="Name" caption="ФИО" width={200} />
                        <Column dataField="Email" caption="Email" width={200} visible={false}/> 
                        <Column dataField="Phone" caption="Телефон" width={150} visible={false}/>
                        <Column dataField="Message" caption="Обращение" width={300} visible={false}/>
                        <Column dataField="TypeMail" caption="Тип обращение" width={100} >
                          <Lookup dataSource={typeInput} displayExpr="title" valueExpr="id" />
                        </Column>
                        <Column dataField="Ip" caption="Ip" width={70}  visible={false}/>
                        <Column dataField="Adres" caption="Адрес" width={100} />
                        <Column dataField="IdAdres" caption="Код адреса" width={150}>
                          <Lookup dataSource={houses1} displayExpr="adres" valueExpr="id" />
                        </Column>
                        <Column dataField="Flat" caption="Квартира" width={50} />
                        <Column dataField="FileFull" caption="Имя файла" width={70} visible={false}/>
                        <Column dataField="Service" caption="Услуга" width={70} visible={false}>
                          <Lookup dataSource={services} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="TypeApplicant" caption="Тип заявителя" width={70} visible={false}>
                        </Column>
                        <Column dataField="TypeTreatment" caption="Тип обращения" width={70} visible={false}>
                        </Column>
                        <Column dataField="Status" caption="Статус" width={70} visible={false}>
                        </Column>
                        <Column dataField="UserResponsible" caption="Ответственный" width={70} visible={false}>
                          <Lookup dataSource={user} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="UserExecutor" caption="Исполнитель" width={70} visible={false}>
                          <Lookup dataSource={user} displayExpr="name" valueExpr="id" />
                        </Column>
                        <Column dataField="DateExecution" dataType="date" caption="Дата выполнения" width={70} visible={false}/>
                        <Column dataField="FileResponse" caption="Файл ответа" width={70} visible={false}/>
                        <Column dataField="DateClose" dataType="date" caption="Дата закрытия" width={70} visible={false}/>
                        <Column dataField="IdClose" caption="Закрытие" width={70} visible={false}>
                        </Column>
                        <Column dataField="IdReturn" caption="Продолжение" width={70} visible={false}>
                        </Column>

                        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
                        <Paging defaultPageSize={10} />
                    </DataGrid>    
             </div> 
           </div> 

  )
}  

export default objectsGrid;