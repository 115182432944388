import React, { Component } from 'react';
import Admin from '../Components/Admin';

export default class error extends Component {
    constructor() {
        super()
        this.role = window.localStorage.getItem('in_role')
        this.fio = window.localStorage.getItem('in_fio')
    }

    render() {
        if (this.role === 'admin') {
            return (
                <div>
                    <Admin fio={this.fio} role={this.role}/>
                </div>
            )}        
            return (
                <div>
                        <h2> { this.fio } Нет доступа к страницы </h2>
                        <a href="/login"> Авторизоваться </a>
                </div>
    
            )    
    
    }
}
