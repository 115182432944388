import React, { Component } from 'react';
import Objects from '../Components/Objects';
import Contact from '../Components/Contact';

export default class objects extends Component {
    render() {
        return (
            <div>
                <Objects />
                <Contact />
            </div>
    )
    }
}
