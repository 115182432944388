import { useState, useCallback, useEffect } from 'react'
import styles from './image.css'

const Image = ({ zoom, src_big, ...rest }) => {
  const [click, setClick] = useState(true)

  const setFlag = () => {
    setClick(true)
    console.log(src_big)
  }

  const unsetFlag = () => {
    setClick(false)
  }
  
  // console.log(...rest)

  if (!zoom) return <img {...rest} />
  else
    return (
      
      <>
        {click ? (
          <div onClick={unsetFlag} className={`${styles.lightbox} ${styles.show} relative`}>
            <img {...rest} className={`${styles.show_image}`} style={{cursor:'zoom-in'}}></img>
          </div>
          
        ) : (
            <img src={`${src_big}`} onClick={setFlag} style={{cursor:'zoom-out'}}></img>
        )}
      </>
    )
}

export default Image