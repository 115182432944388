import React, { useState } from "react";
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
// import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
// import { createStyles, makeStyles } from '@material-ui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@material-ui/core/Container';
import Axios from "axios";
import "../App.css";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@material-ui/icons/EmojiPeople';
import RoleIcon from '@material-ui/icons/Build';
import Avatar from '@mui/material/Avatar';



function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.bi-help.ru/">
          Команда слабоумия и отвага
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
 
const theme = createTheme();  

// const useStyles = makeStyles((theme) => ({
//     paper: {
//       marginTop: theme.spacing(8),
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//     },
//     avatar: {
//       margin: theme.spacing(1),
//       backgroundColor: theme.palette.secondary.main,
//     },
//     form: {
//       width: '100%', // Fix IE 11 issue.
//       marginTop: theme.spacing(1),
//     },
//     submit: {
//       margin: theme.spacing(3, 0, 2),
//     },
//   }));



export default function Registration() {
    // const classes = useStyles();
//   const [usernameReg, setUsernameReg] = useState("");
//   const [passwordReg, setPasswordReg] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");

  // const [loginStatus, setLoginStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const login = () => {
    Axios.post("api/users/login", {
      username: username,
      password: password,
    }).then((response) => {
        setName(response.data.name);
        setRole(response.data.role);
        window.localStorage.setItem('in_fio', response.data.name)
        window.localStorage.setItem('in_role', response.data.role)        
        // console.log(1, name);
      }
    )
//    window.location.assign('/');
  };

return (
  <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <div >
        <Avatar  style={name !== ''?{ background: 'green' }:{}}>
            { name === '' && <LockOutlinedIcon />}
            { name !== '' && <LockOpenOutlinedIcon style={{ background: 'green' }}/>}
        </Avatar>
        <Typography component="h1" variant="h5">
            Вход
        </Typography>
          <div className="login">
            <TextField
    //            type="text"
                 placeholder="Username..."
    //            variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <p/>
            <TextField
              type="password"
              label="Password"              
              placeholder="Password..."
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            { name === '' && 
              <Button 
                onClick={login}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                
              > 
                Войти
              </Button>
            }
            { name !== '' && 
              <Button 
                href='/'
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                
              > 
                Перейти
              </Button>
            }

          </div>
            <Grid container>
                <Grid item xs>
                    {/* <Link variant="body2"> */}
                        { name !== '' &&       
                            // <Typography variant="body2" color="textSecondary" align="center">
                            //     {'Пользователь - '+name+', роль - '+role }
                            //     <p>
                            //     <Link color="inherit" href="/reports">
                            //         Просмотрет отчеты
                            //     </Link>
                            //     </p>
                            // </Typography>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <ImageIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={name} secondary="пользователь" />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                      <RoleIcon />
                                    </Avatar>
                                 </ListItemAvatar>
                                 <ListItemText primary={role} secondary="роль" />
                              </ListItem>
                          </List>
                        }
                    {/* </Link> */}
                </Grid>
            </Grid>    
          <Box mt={8}>
            <Copyright />
          </Box>
        </div>
      </Container>
    </ThemeProvider>  
    );
}
